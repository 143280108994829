import { Translate } from "lib/i18n/client";
import { IncomingMessageWithFullContext } from "types";

import { identityCookieName } from "./identity";

export const bffServer: BFFServer = (req, locale) => {
  const authToken = req?.cookies?.[identityCookieName];
  return configure({
    host: `${req?.context.brand.baseUrl || ""}/myplace`,
    headers: {
      "Accept-Language": bbfLocaleMap[locale] || "en",
      ...(authToken ? { Authorization: `Bearer ${authToken}` } : {}),
    },
  });
};

export const bffClient: BFFClient = (locale) =>
  configure({
    host: "/myplace",
    credentials: "same-origin",
    headers: {
      "Accept-Language": bbfLocaleMap[locale] || "en",
    },
  });

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const bffClientSwr = async ([url, locale]: string[]): Promise<any> => {
  const config = {
    host: "/myplace",
    headers: {
      "Accept-Language": bbfLocaleMap[locale] || "en",
    },
  };
  return await fetchBff(url, config);
};

const configure: Configure = (config) => ({
  purchase: (id) => fetchBff(`/public/v1/purchases/${id}`, config),
  reservation: (id) => fetchBff(`/public/v1/reservation/${id}`, config),
  reservationCheckin: (id) =>
    fetchBff(`/public/v1/reservation/${id}/checkin`, {
      ...config,
      method: "POST",
      body: JSON.stringify({ reservationId: id }),
    }),
  reservationCheckout: (id) =>
    fetchBff(`/public/v1/reservation/${id}/checkout`, {
      ...config,
      method: "POST",
      body: JSON.stringify({ reservationId: id }),
    }),
  reservationAddTravelPurpose: (id, travelPurpose) =>
    fetchBff(`/public/v1/reservation/${id}/preferences`, {
      ...config,
      method: "POST",
      body: JSON.stringify({
        preferences: [
          {
            type: "TRAVEL_PURPOSE",
            preference: travelPurpose,
          },
        ],
      }),
    }),
  reservationUpdateTravelPurpose: (id, preferenceId, travelPurpose) =>
    fetchBff(`/public/v1/reservation/${id}/preferences`, {
      ...config,
      method: "PUT",
      body: JSON.stringify({
        preferences: [
          {
            id: preferenceId,
            type: "TRAVEL_PURPOSE",
            preference: travelPurpose,
          },
        ],
      }),
    }),
  sendMessage: (title, description, email, categoryIds, message_context) =>
    fetchBff(`/public/v2/chat`, {
      ...config,
      method: "POST",
      body: JSON.stringify({
        title,
        description,
        email,
        categoryIds,
        message_context,
      }),
    }),
  buildInvitationLink: (id) =>
    fetchBff(`/public/v1/reservation/${id}/invitation-link`, config),
  validateInviteToken: (token) =>
    fetchBff(`/public/v1/invite/validate-token`, {
      ...config,
      method: "POST",
      body: JSON.stringify({ token }),
    }),
  sendInvite: (request) =>
    fetchBff(`/public/v1/invite/send`, {
      ...config,
      method: "POST",
      body: JSON.stringify(request),
    }),
  acceptInvite: (inviteId, token) =>
    fetchBff(`/public/v1/invites/${inviteId}/accept`, {
      ...config,
      method: "POST",
      body: JSON.stringify({ token }),
    }),
  removeInvite: (reservationId, inviteId) =>
    fetchBff(`/public/v1/reservations/${reservationId}/invites/${inviteId}`, {
      ...config,
      method: "DELETE",
    }),
  products: (reservationId) =>
    fetchBff(`/public/v1/reservation/${reservationId}/products`, config),
  order: (reservationId, items) =>
    fetchBff(`/public/v1/reservation/${reservationId}/order`, {
      ...config,
      method: "POST",
      body: JSON.stringify({ items }),
    }),
  identityDetect: (documentPicture) =>
    fetchBff(`/public/v2/identity/detect-features`, {
      ...config,
      method: "POST",
      body: JSON.stringify({ identity_document_picture: documentPicture }),
    }),
  identityVerify: (documentPicture, selfiePicture) =>
    fetchBff(`/public/v2/identity/verify`, {
      ...config,
      method: "POST",
      body: JSON.stringify({
        identity_document_picture: documentPicture,
        selfie_picture: selfiePicture,
      }),
    }),
  joinCommunity: (request) =>
    fetchBff(`/public/v1/community`, {
      ...config,
      method: "POST",
      body: JSON.stringify(request),
    }),
  registrationFormConstraints: (request) =>
    fetchBff(`/public/v1/compliance/registrationForm`, {
      ...config,
      method: "POST",
      body: JSON.stringify(request),
    }),
  billRecipientFormConstraints: (request) =>
    fetchBff(`/public/v1/compliance/bill-recipient-form`, {
      ...config,
      method: "POST",
      body: JSON.stringify(request),
    }),
  requestInvoice: (purchaseId, request) =>
    fetchBff(`/public/v2/purchases/${purchaseId}/invoice-requests`, {
      ...config,
      method: "POST",
      body: JSON.stringify(request),
    }),
});

const fetchBff: FetchBFF = async (endpoint, { host, headers, ...rest }) => {
  const response = await fetch(`${host}${endpoint}`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      ...headers,
    },
    ...rest,
  });

  if (!response.ok) {
    let body: { code?: string; message?: string } = {};
    try {
      body = await response.json();
    } catch (err) {
      // Fallback for legacy errors not returning JSON body
      throw new HttpError("Error fetching data from BFF", response.status);
    }

    throw new HttpError(
      "Error fetching data from BFF",
      response.status,
      body?.code,
      body?.message,
    );
  }

  return await response.json();
};

const bbfLocaleMap: { [key: string]: string } = {
  es: "es-ES",
  en: "en-US",
  fr: "fr-FR",
  pt: "pt-PT",
};

type Endpoints = {
  purchase: (id: string) => Promise<Purchase>;
  reservation: (id: string) => Promise<Reservation>;
  reservationCheckin: (id: string) => Promise<Record<string, never>>;
  reservationCheckout: (id: string) => Promise<Record<string, never>>;
  reservationAddTravelPurpose: (
    id: string,
    travelPurpose: string,
  ) => Promise<void>;
  reservationUpdateTravelPurpose: (
    id: string,
    preferenceId: string,
    travelPurpose: string,
  ) => Promise<void>;
  sendMessage: (
    title: string,
    description: string,
    email: string | undefined,
    categoryIds: string[] | undefined,
    messageContext: MessageContext,
  ) => Promise<void>;
  buildInvitationLink: (reservationId: string) => Promise<InvitationLink>;
  validateInviteToken: (token: string) => Promise<void>;
  sendInvite: (request: InviteRequest) => Promise<void>;
  acceptInvite: (inviteId: string, token: string) => Promise<void>;
  removeInvite: (reservationId: string, inviteId: string) => Promise<void>;
  products: (reservationId: string) => Promise<Array<StoreProduct>>;
  order: (
    reservationId: string,
    items: Array<StoreOrderItem>,
  ) => Promise<Record<string, never>>;
  identityDetect: (
    documentPicture: string,
  ) => Promise<{ data: { face_detected: boolean } }>;
  identityVerify: (
    documentPicture: string,
    selfiePicture: string,
  ) => Promise<void>;
  joinCommunity: (request: JoinCommunityRequest) => Promise<void>;
  registrationFormConstraints: (
    request: QueryRegistrationFormConstraintsRequest,
  ) => Promise<QueryRegistrationFormConstraintsResponse>;
  billRecipientFormConstraints: (
    request: QueryBillRecipientFormConstraintsRequest,
  ) => Promise<QueryBillRecipientFormConstraintsResponse>;
  requestInvoice: (
    purchaseId: string,
    request: RequestInvoiceRequest,
  ) => Promise<void>;
};

export type MessageContext = {
  purchase_id?: string;
  reservation_id?: string;
  chain_id: string;
  brand_id: string;
  category_ids?: string[];
};

type BFFServer = (
  req: IncomingMessageWithFullContext & {
    cookies?: { [key: string]: string };
  },
  locale: string,
) => Endpoints;

type BFFClient = (locale: string) => Endpoints;

type Configure = (
  config: {
    host: string;
  } & RequestInit,
) => Endpoints;

type FetchBFF = <T>(
  endpoint: string,
  options: {
    host: string;
  } & RequestInit,
) => Promise<T>;

export type Purchase = {
  id: string;
  asset: {
    id: string;
    name: string;
    displayName: string;
    address: {
      addressLine: string;
      city: string;
      province: string;
      postalCode: string;
      countryCode: string;
    };
    timezone: string;
    brand: {
      name: string;
    };
    accessConfig?: {
      commonSpaces?: {
        earlyAccess?: {
          checkinDelta?: number;
        };
      };
    };
  };
  startDate: string;
  endDate: string;
  mainInhabitant: Inhabitant;
  booker?: Inhabitant;
  accounting: PurchaseAccounting;
  reservations: Array<Reservation>;
  pin?: string;
};

export type Reservation = {
  id: string;
  purchase: ReservationPurchase;
  assetId: string;
  businessSegment: {
    segment: string;
  };
  status: "PENDING" | "CONFIRMED" | "CHECKED_IN" | "CHECKED_OUT" | "CANCELLED";
  confirmationNumber: string;
  requestedCategory: {
    id: string;
    name: string;
    type: "DORM" | "BED" | "ROOM" | "APARTMENT";
  };
  assignedSpaceConfig?: {
    categoryId: string;
    space: {
      id: string;
      name: string;
      floor: string;
      status: string;
    };
  };
  createdDate: string;
  cancellationDate?: string | null;
  checkinDate: string;
  checkoutDate: string;
  mainInhabitant: Inhabitant;
  booker?: Inhabitant;
  group: ReservationGroup;
  accounting: {
    totalAmount: Money;
  };
  checkinContext: ReservationCheckinContext;
};

export type ReservationCheckinContext = {
  hostStatus: "READY" | "UNPREPARED";
  inhabitantStatus: "READY" | "UNPREPARED";
  eligible: boolean;
  entryFormsPending: number;
  groupIsReady: boolean;
};

export type ReservationPurchase = {
  id: string;
  assetId: string;
  businessSegment: {
    segment: string;
  };
  checkinDate: string;
  checkoutDate: string;
  mainInhabitant: Inhabitant;
  booker?: Inhabitant;
  accounting: PurchaseAccounting;
};

export type PurchaseAccounting = {
  totalAmount: Money;
  paid?: Money;
  pending?: Money;
  status: "COMPLETE" | "INCOMPLETE" | "REQUIRES_MANUAL_REVIEW";
};

export type ReservationGroup = {
  adultCount: number;
  childCount: number;
  status: "COMPLETE" | "INCOMPLETE";
  inhabitants: Array<Inhabitant>;
  invites: Array<Invite>;
};

export type InvitationLink = {
  link: string;
};

export type Inhabitant = {
  id: string;
  verified: boolean;
  nationalityCode: string;
  nationalityProvince: string;
  email: string;
  phone?: string;
  firstName?: string;
  lastName: string;
  secondLastName: string;
  gender: Gender;
  birthDate: string;
  identityDocumentType: string;
  identityDocumentNumber: string;
  identityDocumentIssueDate: string;
  identityDocumentExpirationDate?: string;
  identityVerifications: Array<{ id: string; verifiedAt: string }>;
  status: string;
  marketingCommunicationsAccepted: boolean;
  conditionsAccepted: boolean;
  dataCommitment: boolean;
  complete: boolean;
};

export type Invite = {
  id: string;
  first_name: string;
  last_name: string;
};

export type Money = {
  amount: number;
  currency: string;
};

export type MoneyV2 = {
  amount: string;
  currency: string;
};

type Gender = "MALE" | "FEMALE";

export type InhabitantRequest = {
  firstName: string;
  lastName: string;
  secondLastName?: string;
  email: string;
  phone: string;
  nationalityCode: string;
  nationalityProvince?: string;
  gender: Gender;
  birthDate: string;
  identityDocumentType: string;
  identityDocumentNumber: string;
  identityDocumentIssueDate: string;
  identityDocumentExpirationDate?: string | null;
  signature: string;
  marketingCommunicationsAccepted: boolean;
  conditionsAccepted: boolean;
  dataCommitment: boolean;
};

export type InviteRequest = {
  firstName: string;
  lastName: string;
  email: string;
  token: string;
};

export type JoinCommunityRequest = {
  firstName?: string;
  lastName?: string;
  email?: string;
  marketingCommunicationsAccepted: boolean;
};

export type QueryRegistrationFormConstraintsRequest = {
  assetId: string;
  checkinTime: string;
  target:
    | "FORM_TARGET_MAIN_INHABITANT"
    | "FORM_TARGET_COMPANION"
    | "FORM_TARGET_ANON_COMPANION";
  type?: "STAY_TYPE_TOURIST" | "STAY_TYPE_RESIDENTIAL";
  nationalityCode?: string;
  nationalityProvince?: string;
  addressCountryCode?: string;
  addressProvinceCode?: string;
  birthDate?: string;
  documentType?: string;
};

export type QueryRegistrationFormConstraintsResponse = {
  data: RegistrationFormConstraints;
};

export type RegistrationFormConstraints = {
  constraints: {
    firstName: FieldConstraint;
    lastName: FieldConstraint;
    secondLastName: FieldConstraint;
    gender: FieldConstraint;
    kinship: FieldConstraint;
    birthDate: FieldConstraint;
    nationalityCode: FieldConstraint;
    nationalityProvince: FieldConstraint;
    emailAddress: FieldConstraint;
    phoneNumber: FieldConstraint;
    addressLine: FieldConstraint;
    addressCity: FieldConstraint;
    addressPostalCode: FieldConstraint;
    addressCountryCode: FieldConstraint;
    addressProvinceCode: FieldConstraint;
    documentType: FieldConstraint;
    documentNumber: FieldConstraint;
    documentIssueDate: FieldConstraint;
    documentExpirationDate: FieldConstraint;
    billingCountry: FieldConstraint;
    billingProvince: FieldConstraint;
    tcAccept: FieldConstraint;
    rulesAccept: FieldConstraint;
    dataVeracityCommitment: FieldConstraint;
    signature: FieldConstraint;
    travelPurpose: FieldConstraint;
  };
  skipped: boolean;
};

export type QueryBillRecipientFormConstraintsRequest = {
  assetId: string;
  nationalityCode: string;
  isCompany: boolean;
};

export type QueryBillRecipientFormConstraintsResponse = {
  data: {
    constraints: {
      name: FieldConstraint;
      companyName: FieldConstraint;
      vatIdentificationNumber: FieldConstraint;
      address: FieldConstraint;
      nationalityCode: FieldConstraint;
      nationalityProvince: FieldConstraint;
    };
  };
};

export type FieldConstraint = {
  required: boolean;
  skipped: boolean;
  text?: {
    regexp?: string;
    placeholder?: LocalizedText;
  };
  select?: {
    selectOptions?: Array<SelectOption>;
  };
};

export type SelectOption = {
  value: string;
  localizedLabels: Array<LocalizedText>;
};

export type LocalizedText = {
  text: string;
  languageCode: string;
};

export type PaymentIntent = {
  amount: Money;
  authorize_only: boolean;
  platform: string;
  data: {
    publishable_key: string;
    pi_client_secret: string;
  };
};

export type StoreProduct = {
  id: string;
  name: string;
  description: string;
  image?: {
    uri: string;
    srcSet: string;
    altText: string;
  };
  chargingType: "once" | "per_night" | "per_person" | "per_person_per_night";
  price: {
    gross: Money;
    old?: Money;
  };
  preferenceFields: Array<{
    key: string;
    type: "text" | "textarea";
    label: string;
    description: string;
    required: boolean;
  }>;
  availability?: {
    unitsCount: number;
    availableForSale: boolean;
  };
};

export type StoreOrderItem = {
  productId: string;
  quantity?: number;
  preferences: Array<{
    key: string;
    value: string;
  }>;
};

export type ItemsResponse = {
  items: Array<ReservationItem>;
  products: Array<StoreProduct>;
};

export type ReservationItem = {
  id: string;
  type: string;
  productId: string;
  value: Money;
  consumptionTime: string;
  fulfilmentStatus: "pending" | "confirmed" | "cancelled";
};

export type InhabitantResponse = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  marketingCommunicationsAccepted: boolean;
  isInCommunity: boolean;
};

export type StoreCategory = {
  id: string;
  name: string;
  categories: StoreCategory[];
  icon: {
    data: string;
  };
  products: StoreProduct[];
};

export type RequestInvoiceRequest = {
  invoice_request: {
    email: string;
    notes: string;
  };
  recipient_info: {
    recipient_name: string;
    recipient_company_name: string;
    recipient_vat_identification_number: string;
    recipient_address: string;
    recipient_province: string;
    recipient_country_code: string;
  };
  request_for_different_person: boolean;
};

export type ReservationInhabitantPreferences = {
  preferences: ReservationInhabitantPreference[];
};

export type ReservationInhabitantPreference = {
  id: string;
  type: string;
  preference: string;
};

export class HttpError extends Error {
  public readonly statusCode: number;
  public readonly errorCode?: string;
  public readonly errorMessage?: string;

  constructor(
    message: string,
    statusCode: number,
    errorCode?: string,
    errorMessage?: string,
  ) {
    super(`${message}. Status code: ${statusCode}; Error code: ${errorCode}`);
    this.statusCode = statusCode;
    this.errorCode = errorCode;
    this.errorMessage = errorMessage;
  }
}

export const ErrorMap = {
  200: "errors:defaultError.ok",
  400: "errors:defaultError.bad_request",
  401: "errors:defaultError.unauthorized",
  403: "errors:defaultError.forbidden",
  404: "errors:defaultError.not_found",
  409: "errors:defaultError.conflict",
  429: "errors:defaultError.too_many_requests",
  499: "errors:defaultError.canceled",
  500: "errors:defaultError.server_error",
  501: "errors:defaultError.not_implemented",
  503: "errors:defaultError.service_unavailable",
  504: "errors:defaultError.gateway_timeout",
} as { [key: number]: string };

export interface ErrorDetail {
  "@type": string;
}

export const ErrorInfoType = "type.googleapis.com/google.rpc.ErrorInfo";

export interface ErrorInfo extends ErrorDetail {
  reason: string;
  metadata?: { [key: string]: string };
}

export class StatusHttpError extends Error {
  public readonly statusCode: number;
  public readonly errorCode: number;
  public readonly errorMessage: string;
  public readonly errorDetails: Array<ErrorDetail>;

  constructor(
    statusCode: number,
    errorCode: number,
    errorMessage: string,
    errorDetails?: Array<ErrorDetail>,
  ) {
    super(`HTTP Status code: ${statusCode}; Error code: ${errorCode}`);
    this.statusCode = statusCode;
    this.errorCode = errorCode;
    this.errorMessage = errorMessage;
    this.errorDetails = errorDetails || [];
  }
}

export const errorTranslation = (
  t: Translate,
  statusErr: unknown,
  customErrorPrefix = "error.",
): string => {
  if (!(statusErr instanceof StatusHttpError)) {
    return t("errors:defaultError.unknown_error");
  }
  if (statusErr.errorDetails && statusErr.errorDetails.length > 0) {
    const details = statusErr.errorDetails[0];
    if (details["@type"] === ErrorInfoType) {
      // We could add more types here
      return t(
        `${customErrorPrefix}${(details as ErrorInfo).reason.toLowerCase()}`,
        {
          ...(details as ErrorInfo).metadata,
          defaultValue:
            ErrorMap[statusErr.errorCode] ||
            "errors:defaultError.unknown_error",
        },
      );
    }
  }
  return t(
    ErrorMap[statusErr.errorCode] || "errors:defaultError.unknown_error",
  );
};
